import React, { FC, useContext } from 'react';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { SignpostPanelType } from '../../../models/signpost-panel-type';
import TextLink from '../../atoms/text-link/text-link';

const SignpostPanel: FC<SignpostPanelType> = ({
  description,
  heading,
  link,
  panelColour,
}: SignpostPanelType) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const background =
    panelColour === 'light-blue' ? 'bg-light-blue' : 'bg-floral';

  return (
    <div className={`${background} rounded-lg px-8 py-6`}>
      <h5 className="page-heading-four">{heading}</h5>

      {description && <p className="mt-2 text-grey-600">{description}</p>}

      {link && (
        <TextLink
          {...link}
          className="mt-4"
          onClick={() => {
            pushToDataLayer(GtmEventNameEnum.SignpostCard, {
              cardTitle: heading,
            });
          }}
          showIcon
        />
      )}
    </div>
  );
};

export default SignpostPanel;
