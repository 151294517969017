import React, { FC } from 'react';
import { SignpostPanelListType } from '../../../models/signpost-panel-list-type';
import SignpostPanel from '../../molecules/signpost-panel/signpost-panel';

const SignpostPanelList: FC<SignpostPanelListType> = ({
  signpostPanels,
}: SignpostPanelListType) => {
  const gridCols =
    signpostPanels.length > 1 ? 'grid-cols-1 md:grid-cols-2' : 'grid-cols-1';

  return (
    <div
      className={`py-component-spacing-mobile lg:py-component-spacing grid ${gridCols} gap-4 mx-auto w-full lg:w-10/12 xl:w-8/12`}
    >
      {signpostPanels.map((panel) => (
        <SignpostPanel key={panel.heading} {...panel} />
      ))}
    </div>
  );
};

export default SignpostPanelList;
